<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="">
        <!-- <el-button class="right"
               style="width: 88px; height: 36px; margin-top: -46px;"
               @click="$router.go(-1)">返回</el-button> -->
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="欠费区间" prop="debtInterval">
                <el-select
                  v-model.trim="formInline.debtInterval"
                  filterable
                  size="15"
                  style="width: 150px"
                >
                  <el-option label="200元以内" value="1"></el-option>
                  <el-option label="200元~500元" value="2"></el-option>
                  <el-option label="500元以上" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  :maxlength="8"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="车牌号"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="是否会员" prop="isUser">
                <el-select
                  v-model.trim="formInline.isUser"
                  filterable
                  size="15"
                  style="width: 150px"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="非会员" value="0"></el-option>
                  <el-option label="会员" value="1"></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Vehicle_type')" label-width="80px">
          <el-select filterable v-model.trim="formInline.plateNumberType">
            <el-option label="全部" value="0"></el-option>
            <el-option label="不含无牌车" value="1"></el-option>
            <el-option label="无牌车" value="2"></el-option>
          </el-select>
        </el-form-item> -->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <!-- <el-button type="primary"
            icon="el-icon-tickets"
            @click="exportExcelReport()"
            :loading="loading"
            >{{ $t('button.export') }}</el-button> -->
          <!-- v-if='authority.button.export' -->
        </el-form>
      </div>

      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="150">
            <template slot-scope="scope">
              <!--<el-button type="text" @click="fn(scope)">详情</el-button>-->
              <el-button type="text" @click="oepnDetail(scope.row.carId)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <el-dialog title="欠费总分析详情" :visible.sync="dialogTableVisible">
          <licensePlateOwe :carId="carId"></licensePlateOwe>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { exportExcelNew } from "@/common/js/public.js";

import licensePlateOwe from "./licensePlateOwe";
export default {
  name: "arrearageSummation",
  components: {
    licensePlateOwe,
  },
  data() {
    return {
      dialogTableVisible: false,
      carId: "",

      // authority: this.$setAuthority('/arrearageParkingList'),
      businessTypes: [],
      loading: false,
      total: 0,
      pageNum: 1,
      pageSize: 15,
      tableData: [],
      formInline: {
        debtInterval: "3",
        plateNumber: "",
        carId: "",
        isUser: "",
      },
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "phoneNumber",
          label: this.$t("list.phone_number"),
          width: "",
        },
        {
          prop: "totalArrearageAmount",
          label: this.$t("list.arrears"),
          width: "",
          formatter: (row, column) => {
            if (row.totalArrearageAmount) {
              let price = Number(row.totalArrearageAmount / 100).toFixed(2);
              price =
                price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + price.split(".")[1];
              //              return (Number((row.totalArrearageAmount) / 100).toFixed(2)).toString().replace(/(\d)(?=(?:\d{3})+$)/g, ",");
              return price;
            } else {
              return "0.00";
            }
          },
        },
      ],
    };
  },
  mounted() {
    this.searchData();
  },
  methods: {
    oepnDetail(carId) {
      console.log(typeof carId);
      this.carId = carId;
      this.dialogTableVisible = true;
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
      //		      this.searParkRecordList();
    },
    querySearchAsync(queryString, cb) {
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },

    searchData() {
      if (this.formInline.debtInterval) {
        this.$axios
          .get("/acb/2.0/debtStatistics/arrearageplatelist", {
            data: {
              debtInterval: this.formInline.debtInterval,
              plateNumber: this.formInline.plateNumber,
              isUser: this.formInline.isUser,
              pageNumber: this.pageNum,
              pageSize: this.pageSize,
              // plateNumberType: this.formInline.plateNumberType
            },
          })
          .then((res) => {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          });
      } else {
        this.$alert("请选择欠费区间");
      }
    },
    // 导出
    exportExcelReport() {
      if (this.formInline.debtInterval) {
        exportExcelNew("/acb/2.0/debtStatistics/arrearageplatelistExport", this.formInline, "post");
      } else {
        this.$alert("请选择欠费区间");
      }
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
